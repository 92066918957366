import tacOps from "./tac-ops-cards.json";
import { TacOpsCategoryType } from "../types/tac-ops-data";

let sortedTacOps = [...tacOps];
sortedTacOps[1].content = sortCategories(sortedTacOps[1].content);

function sortCategories(categoryList: TacOpsCategoryType[]) {
  return categoryList.sort((a, b) => {
    if (a.title > b.title) {
      return 1;
    }
    if (a.title < b.title) {
      return -1;
    }
    return 0;
  });
}

export default sortedTacOps;
